<template>
    <div>
        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard-analytics' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{  $t('label_user_settings') }}</b-breadcrumb-item>
            </div>
        </div>
        <b-col cols="12" md="12">
            <b-card
                    no-body
                    class="mb-0"
            >
                <b-card-header class="pb-50">
                    <h5>{{ $t('label_user_settings') }}</h5>
                </b-card-header>

                <validation-observer
                        #default="{ handleSubmit, invalid }"
                        :ref="PREFIX + '_FORM'"
                        tag="form"
                        class="p-2"
                >
                   <b-row class="mb-2">
                       <b-col cols="12" md="3" class="d-flex justify-content-center">
                           <!--<div >-->
                               <div  class="added-img-wrap avatar-uploading">
                                   <file-input
                                           v-model="profile_picture"
                                           class="file--uploader size--big drop-block mb-3 no-counter"
                                           :max-files-count='10'
                                           :required="true"
                                           :multiple="false"
                                           allowed-extensions="*"
                                           :max-file-size='15'
                                           :url="''"
                                   >
                                       <template #uploadBody>
                                           <!--<img :src="require('@/assets/images/upload.svg')" class="mr-1">-->
                                           <!--{{$t('label_add_file')}}-->
                                           <!--:onerror="$refs['avatar']? $refs['avatar'].src = require('@/assets/images/avatar.png') : false"-->
                                           <img  v-if="savedAvatar && profile_picture.length < 1" ref="avatar"

                                                :src=" $domain + savedAvatar" alt="avatar">
                                           <img v-else
                                                :src="profile_picture.length > 0? profile_picture[0].base64: require('@/assets/images/avatar.png')" alt="avatar">

                                       </template>

                                       <template #errorBug="fileErrors">

                                           <span v-if="validationProps.failedRules.length > 0 || fileErrors.errors.length > 0">{{validationProps.failedRules.length  > 0 ?  validationProps.errors[0] : fileErrors.errors[0] }}</span>
                                           <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                               {{ validationProps.errors[0] }}
                                           </b-form-invalid-feedback>
                                       </template>
                                   </file-input>

                                   <!--<img v-if="logo.length > 0" alt="logo" :src="logo[0].base64" >-->
                                   <!--<img v-else alt="logo" :src="uploadedLogo? $domain+uploadedLogo : require('@/assets/images/no-image-2.png')" >-->

                                   <!--<div v-if="(logo.length > 0 || uploadedLogo)"  @click="logo.length > 0? logo = [] :deleteLogo()" class="delete-img server-deleting" >-->
                                       <!--<feather-icon class="cursor-pointer"  icon="TrashIcon"></feather-icon>-->
                                   <!--</div>-->
                               </div>
                           <!--</div>-->

                       </b-col>
                       <b-col cols="12" md="9">
                           <!-- Username -->
                           <validation-provider
                                   #default="validationProps"
                                   :name="$t('label_first_name')"
                                   rules="required"
                           >
                               <b-form-group
                                       :label="$t('label_first_name')"
                                       class="required"
                               >
                                   <b-form-input

                                           v-model="itemData.firstName"
                                           :placeholder="$t('label_first_name')"
                                           :state="getValidationState(validationProps)"
                                           trim
                                   />

                                   <b-form-invalid-feedback>
                                       {{ validationProps.errors[0] }}
                                   </b-form-invalid-feedback>
                               </b-form-group>
                           </validation-provider>

                           <validation-provider
                                   #default="validationProps"
                                   :name="$t('label_last_name')"
                                   rules="required"
                           >
                               <b-form-group
                                       :label="$t('label_last_name')"
                                       class="required"
                               >
                                   <b-form-input
                                           v-model="itemData.lastName"
                                           :placeholder="$t('label_last_name')"
                                           :state="getValidationState(validationProps)"
                                           trim
                                   />

                                   <b-form-invalid-feedback>
                                       {{ validationProps.errors[0] }}
                                   </b-form-invalid-feedback>
                               </b-form-group>
                           </validation-provider>

                           <validation-provider
                                   #default="validationProps"
                                   :name="$t('label_position')"
                                   rules=""
                           >
                               <b-form-group
                                       :label="$t('label_position')"
                                       class=""
                               >
                                   <b-form-input
                                           v-model="itemData.position"
                                           :placeholder="$t('label_position')"
                                           :state="getValidationState(validationProps)"
                                           trim
                                   />

                                   <b-form-invalid-feedback>
                                       {{ validationProps.errors[0] }}
                                   </b-form-invalid-feedback>
                               </b-form-group>
                           </validation-provider>

                           <validation-provider
                                   #default="validationProps"
                                   :name="$t('label_timezone')"
                                   rules=""
                           >
                               <b-form-group
                                       :label="$t('label_timezone')"
                                       class=""
                               >
                                   <v-select
                                           :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                           v-model="itemData.timezone"
                                           :options="timezones"
                                           label="name"
                                           class="w-100"
                                           :placeholder="$t('label_select')"
                                           :reduce="val => val.zone"

                                   >
                                       <!--@input="(val) => $emit('update:statusFilter', val)"-->
                                       <template v-slot:option="option">

                                           <span class="">{{ option.name }}</span>
                                       </template>
                                       <template v-slot:selected-option="option">

                                           <span class="">{{ option.name }}</span>
                                       </template>
                                       <template v-slot:no-options="option">
                                           {{$t('label_no_items')}}
                                       </template>
                                   </v-select>
                                   <b-form-invalid-feedback>
                                       {{ validationProps.errors[0] }}
                                   </b-form-invalid-feedback>
                               </b-form-group>
                           </validation-provider>

                       </b-col>
                   </b-row>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_phone')"
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_phone')"
                                class="required"
                        >
                            <b-form-input
                                    v-model="itemData.phone"
                                    :placeholder="$t('label_phone')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            rules="required"
                            #default="validationProps"
                            name="Email"
                    >
                        <b-form-group
                                label="Email"
                                class="required"
                        >
                            <b-form-input
                                    v-model="itemData.emailAddress"
                                    placeholder="Email"
                                    :state="getValidationState(validationProps)"
                                    trim
                                    :readonly="(action == 'editing') ? true : false"
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_private_phone')"
                            rules=""
                    >
                        <b-form-group
                                :label="$t('label_private_phone')"
                                class=""
                        >
                            <b-form-input
                                    v-model="itemData.private_phone"
                                    :placeholder="$t('label_private_phone')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            rules="email"
                            #default="validationProps"
                            :name="$t('label_private_email')"
                    >
                        <b-form-group
                                :label="$t('label_private_email')"
                                class=""
                        >
                            <b-form-input
                                    v-model="itemData.private_email"
                                    :placeholder="$t('label_private_email')"
                                    :state="getValidationState(validationProps)"
                                    trim
                                    :readonly="(action == 'editing') ? true : false"
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>


                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_work_landline_phone')"
                            rules=""
                    >
                        <b-form-group
                                :label="$t('label_work_landline_phone')"
                                class=""
                        >
                            <b-form-input
                                    v-model="itemData.phone_work_landline"
                                    :placeholder="$t('label_work_landline_phone')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_work_mobile_phone')"
                            rules=""
                    >
                        <b-form-group
                                :label="$t('label_work_mobile_phone')"
                                class=""
                        >
                            <b-form-input
                                    v-model="itemData.phone_work_mobile"
                                    :placeholder="$t('label_work_mobile_phone')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                            rules="email"
                            #default="validationProps"
                            :name="$t('label_work_email')"
                    >
                        <b-form-group
                                :label="$t('label_work_email')"
                                class=""
                        >
                            <b-form-input
                                    v-model="itemData.email_work"
                                    :placeholder="$t('label_work_email')"
                                    :state="getValidationState(validationProps)"
                                    trim
                                    :readonly="(action == 'editing') ? true : false"
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            rules=""
                            #default="validationProps"
                            :name="$t('label_gmail')"
                    >
                        <b-form-group
                                :label="$t('label_gmail')"
                                class=""
                        >
                            <b-form-input
                                    v-model="itemData.gmailAddress"
                                    :placeholder="$t('label_gmail')"
                                    :state="getValidationState(validationProps)"
                                    trim
                                    :readonly="(action == 'editing') ? true : false"
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>


                    <validation-provider
                            rules=""
                            #default="validationProps"
                            :name="$t('label_gmail_password')"
                    >
                        <b-form-group
                                :label="$t('label_gmail_password')"
                                class=""
                        >
                            <b-form-input
                                    type="password"
                                    v-model="itemData.gmail_password"
                                    :placeholder="$t('label_gmail_password')"
                                    :state="getValidationState(validationProps)"
                                    trim
                                    :readonly="(action == 'editing') ? true : false"
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_user_hourly_rate')"
                            rules="required|numeric"
                    >
                        <b-form-group
                                :label="$t('label_user_hourly_rate')"
                                class="required"
                        >
                            <b-form-input
                                    v-model="itemData.hourly_rate"
                                    :placeholder="$t('label_user_hourly_rate')"
                                    :state="getValidationState(validationProps)"
                                    trim
                                    :readonly="(action == 'editing') ? true : false"
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>


                    <template v-if="$can('edit', 'settings.core')">
                        <h5 class="mt-3">{{$t('label_marketing')}}</h5>

                        <validation-provider
                                #default="validationProps"
                                :name="$t('label_link')"

                        >
                            <b-form-group
                                    :label="$t('label_link')"

                            >
                                <b-form-input
                                        v-model="marketings_url"
                                        placeholder="https://url"
                                        :state="getValidationState(validationProps)"
                                        trim
                                        :readonly="(action == 'editing') ? true : false"
                                />

                                <b-form-invalid-feedback>
                                    {{ validationProps.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <h5 class="mt-3">{{$t('label_settings_security')}}</h5>
                        <validation-provider
                                #default="validationProps"
                                :name="$t('label_settings_owner_ip')"
                        >
                            <b-form-group
                                    :label="$t('label_settings_owner_ip')"

                            >
                                <b-form-input
                                        v-model="owner_ip"
                                        placeholder="100.112.200.42, 190.112.200.42, ..."
                                        :state="getValidationState(validationProps)"
                                        trim
                                        :readonly="(action == 'editing') ? true : false"
                                />

                                <b-form-invalid-feedback>
                                    {{ validationProps.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>


                    </template>


                    <div class="d-flex mt-2 justify-content-end">
                        <b-button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"
                                  class="mr-2"
                                  type="submit"
                        >
                            {{ action == 'editing'? $t('label_edit') : $t('label_save') }}
                        </b-button>
                        <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                type="button"
                                variant="outline-secondary"
                                v-on:click="$router.go(-1)"
                        >
                            <!--@click="hide"-->
                            {{ $t('label_cancel') }}

                        </b-button>
                    </div>

                </validation-observer>

            </b-card>
        </b-col>
    </div>
</template>

<script>


    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import {
        BCardHeader, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCard, BFormTextarea, BFormCheckbox
    } from 'bootstrap-vue'
    import  fileInput from '@/views/components/fileUploadInput'
    import vSelect from 'vue-select'

    import {serialize} from 'object-to-formdata';

    export default {
        name: "editItem.vue",
        components: {
            BCardHeader,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BButton, BCard,
            vSelect,
            BFormCheckbox,
            BFormTextarea,

            ValidationProvider,
            ValidationObserver,
            fileInput

        },
        data() {
            return {

                PREFIX: 'user_settings',

                itemData: {},

                action: 'adding',

                blankItemData: {
                    firstName: '',
                    lastName: '',
                    hourly_rate: '',
                    phone: '',
                    emailAddress: '',
                    private_phone: '',
                    private_email: '',
                    phone_work_landline: '',
                    timezone:null,
                    email_work: '',
                    phone_work_mobile: '',
                    gmailAddress: '',
                    gmail_password: '',
                    position: '',
                },

                marketings_url: '',
                owner_ip: '',
                timezones:[],

                savedAvatar:false,
                profile_picture: [],
                // authUser: {}

            }
        },
        created() {
            this.initData();
        },
        methods: {
            initData() {

                let user_id = this.authUser().id;
                let item = Object.assign({}, this.blankItemData);

                this.async('get', '/user/' + user_id, {params:{}}, function (resp) {

                    let user = resp.data;

                    item.id = user.id;

                    for (let prop in item) {
                        if (user.hasOwnProperty(prop)) {
                            item[prop] = user[prop];
                        }
                    }

                    this.itemData = item;
                    this.savedAvatar = user.profile_picture;
                });
                this.async('get', '/general_settings', {params:{}}, function (resp) {
                    this.marketings_url = resp.data.marketings_url;
                    this.owner_ip = resp.data.owner_ip;

                });
                this.async('get', '/timezones', {params:{}}, function (resp) {
                    this.timezones = resp.data;
                });

            },

            onSubmit() {


                let formData = new FormData();
                formData = serialize(this.itemData, {}, formData);
                // for(let i =0; i<this.profile_picture.length; i++) {
                    formData.append('profile_picture[]', this.profile_picture[0]);
                // }

                this.async('put', '/user/' + this.authUser().id, formData, function (resp) {

                        let storageUser = this.authUser();
                        let editedItem = resp.data.item;
                         let item = Object.assign({}, this.blankItemData);
                        item.id = editedItem.id;
                        for (let prop in this.blankItemData) {
                            if (editedItem.hasOwnProperty(prop)) {
                                storageUser[prop] = editedItem[prop];
                                item[prop]  = editedItem[prop];
                            }
                        }
                        this.itemData = item;
                        storageUser.profile_picture = editedItem.profile_picture;
                        this.savedAvatar = storageUser.profile_picture;
                        localStorage.setItem('userData', JSON.stringify(storageUser));
                        this.profile_picture = [];

                    }, false, {headers:{'Content-Type': 'multipart/form-data'}});

                    if(this.$can('edit', 'settings.core')){
                        this.async('post', '/general_settings', { marketings_url:this.marketings_url, owner_ip: this.owner_ip}, function (resp) {
                        });
                    }

            },

        }
    }
</script>

<style scoped>
    .added-img-wrap{
        /*margin-bottom: 15px;*/
        border: 1px solid #BDBDBD;
        border-radius: 15px;
        align-self: center;
        display: flex;
        align-items: center;
        padding: 10px;
        position: relative;
    }
    .added-img-wrap img{
        object-fit: contain;
        width: 100%;
        max-width: 130px;
        height: 100%;
        max-height: 224px;
        border-radius: 5px;
    }
    .delete-img{
        position: absolute;
        border-radius: 50%;
        border-style: none;
        top: 7px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 3px;
        font-size: 15px;
        right: 7px;
        width: 20px;
        height: 20px;
        background-color: #EB5757;
        color: white;
    }
</style>